'use strict';
import '@babel/polyfill';
import Swiper from 'swiper/bundle'

import 'picturefill'
import 'picturefill/dist/plugins/intrinsic-dimension/pf.intrinsic.min.js'
import 'custom-event-polyfill'
import ScrollTrigger from '@terwanerik/scrolltrigger'

const findProperty = obj => {
  const el = document.createElement('div')
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && el.style[key] !== undefined) {
      return obj[key]
    }
  }
}
window.transitionEnd = findProperty({
  transition: 'transitionend',
  MozTransition: 'transitionend',
  WebkitTransition: 'webkitTransitionEnd'
})
window.animationEnd = findProperty({
  animation: 'animationend',
  MozAnimation: 'animationend',
  WebkitAnimation: 'webkitAnimationEnd'
})

$(() => {
  $('body').addClass('loaded')
  $('#contents').addClass('loaded')

  new Swiper('.kv-slider', {
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    spaceBetween: 0,
    speed: 2000,
    // centeredSlides: true,
    noSwiping: true,
    allowTouchMove: false,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  })


  // 動画 --------------------------------------------------
  // 再生（失敗した場合はクラス追加)
  $('video').each((index, e) => {
    if (e.paused ) {
      e.play().then(e => {
      }, error => {
        e.classList.add('ready')
      })
    }
  })

  // player ready
  $('video').on('canplaythrough', e => {
    $(e.currentTarget).addClass('ready')
  })

  // 再生開始時にクラス追加
  $('video').on('play', e => {
    $(e.currentTarget).parent().addClass('playing')
  })
  
  // 自動再生非対応環境でクリック再生
  $('video').on('click', e => {
    if (e.currentTarget.paused ) {
      e.currentTarget.play()
    }
  })

  // スクロールエフェクト --------------------------------------------------
  const trigger = new ScrollTrigger({
    trigger: {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.25
          }
        },
        viewport: {
          x: 0,
          y: (trigger, frame, direction) => {
            return 0
          }
        }
      },
    },
  })
  trigger.add('[data-trigger]')


  // setInterval( () => {
  //   $('[data-trigger]').toggleClass('visible')
  // }, 3000 )


});
